<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-28 16:30:02
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-12 23:17:17
-->
<template>
  <div class="page-box">
    <list @load="getList">
      <div class="order" v-for="(item, index) in list" :key="index">
        <order-card
          :info="item"
          :showBtns="item.label === 0 ? false : true"
          :btns="[{ text: '重新报修', type: 'primary' }]"
          showSeal
          :sealIcon="
            item.label === 0 ? waitIcon : item.label === 2 ? unpassIcon : ''
          "
          @onclickbtn="clickBtn"
          @ondetail="detail"
        />
      </div>
    </list>
  </div>
</template>

<script>
import orderCard from "../../../components/OrderCard/OrderCard";
import { orderList } from "../../../utils/api/workOrder";
import { List } from "vant";
import pageLoader from "../../../utils/load_per_page";
export default {
  components: {
    "order-card": orderCard,
    list: List,
  },
  data() {
    return {
      listLoader: null,

      list: [],

      waitIcon: require("../../../assets/images/user/review_wait.png"),
      unpassIcon: require("../../../assets/images/user/review_unpass.png"),
    };
  },
  created() {},

  methods: {
    getList() {
      if (!this.listLoader)
        this.listLoader = new pageLoader(
          orderList,
          "data.data.list.data",
          true,
          "data.data.list.last_page"
        );
      this.listLoader
        .load({
          status: 0,
        })
        .then((res) => {
          if (res) this.list = res;
        })
    },

    clickBtn(e) {
      this.$router.push({
        name: "UserRepair",
      });
    },

    // 详情
    detail(e) {
      this.$router.push({
        name: "UserOrderDetail",
        query: {
          id: e.info.id,
        },
      });
    },
  },
};
</script>

<style  src="../../../assets/css/user/waitReview.css" scoped>
</style>