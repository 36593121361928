import { render, staticRenderFns } from "./WaitReview.vue?vue&type=template&id=7ccb8117&scoped=true&"
import script from "./WaitReview.vue?vue&type=script&lang=js&"
export * from "./WaitReview.vue?vue&type=script&lang=js&"
import style0 from "../../../assets/css/user/waitReview.css?vue&type=style&index=0&id=7ccb8117&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ccb8117",
  null
  
)

export default component.exports